/*
 * @Autor: syq
 * @Date: 2021-07-16 20:47:52
 */
import request from "../../request/request";
import { stringify } from "qs";

const queryMarketList = async () =>
  request({
    url: "/cartLine/list",
    method: "get",
  });

const updateCount = async (params) =>
  request({
    url: `/cartLine/setCartLineQuantity?${stringify(params)}`,
    method: "put",
  });

const isChoiceByIds = async (params) =>
  request({
    url: `/cartLine/choiceByIds?${stringify(params)}`,
    method: "put",
  });

const caculatePrice = async (params) =>
  request({
    url: `/cartLine/caculatePromotion`,
    method: "put",
    data: params,
  });

const deleteCommdit = async (id) =>
  request({
    url: `/cartLine/${id}/1`,
    method: "DELETE",
  });
const deletezengpin = async (id) =>
  request({
    url: `/cartLine/${id}/0`,
    method: "DELETE",
  });
export {
  queryMarketList,
  updateCount,
  isChoiceByIds,
  caculatePrice,
  deleteCommdit,
  deletezengpin
};
