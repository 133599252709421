<!--
 * @Autor: syq
 * @Date: 2021-07-09 19:46:03
-->
<template>
  <div id="market">
    <div v-if="list.length === 0" class="kongwarp">
      <img :src="kong" />
      <p>购物车暂无商品</p>
    </div>

    <!-- 头部 -->
    <div class="market-header">
      <p>{{ `共&nbsp;${listCount}&nbsp;件宝贝` }}</p>
      <a @click="handleClick">{{ editFlag ? "取消" : "编辑" }}</a>
    </div>

    <!-- 商品 -->
    <div class="store-goods" v-for="(i,index) in list" :key="index">
      <div class="store-header">
        <div class="store-logo">
          <img :src="findlogo(i)" alt="123" />
        </div>
        <div class="store-title" @click="jumpstore(i)">{{ i.storeName }}</div>
      </div>
      <div v-for="(active, index) in i.activityList.filter(
          (d) =>
            d.templateName !== '限时折扣' &&
            d.templateName !== '限时减价' &&
            d.templateName !== '限时特价'
        )" 
       :key="index"
       v-show="findMessagePromtion(active)"
      >
        <div
          class="store-gift"
          v-if="active.activityType != 2"
        >
          <span>
            <span v-for="(item, index) in active.messagePromtion" :key="index">
              <span v-if="active.notShowAction">
                {{
                  `${getManjianActivityFirstLineIs12(active) ? '满' : '每满' }${item.range}${
                      getManjianActivityUnieIs13(active) ? "元" : "件"
                  }赠${item.number}件;`
                }}
              </span>
              <span v-if="item.isGet">
                {{
                  `已享${getManjianActivityFirstLineIs12(active) ? '满' : '每满' }${item.range}${
                      getManjianActivityUnieIs13(active) ? "元" : "件"
                  }赠${item.number}件;`
                }}
              </span>
              <span v-if="!item.isGet && !active.notShowAction">
                {{
                  `再买${item.range}${
                    getManjianActivityUnieIs13(active) ? "元" : "件"
                  }可享受满${item.unRange}${
                    getManjianActivityUnieIs13(active) ? "元" : "件"
                  }赠${item.unvalue}件`
                }}
              </span>
            </span>
          </span>

          <p @click="collectgift(active)"><span v-if="!active.notShowAction">去选购 ></span></p>
        </div>
        <div class="store-total-activity" v-if="active.activityType == 2">
          <div class="store-activity">
            <div >
              <span v-for="(item, index) in active.messagePromtion" :key="index">
                <span v-if="active.notShowAction">
                  {{
                    `${getManjianActivityFirstLineIs12(active) ? '满' : '每满' }${item.range}${
                        getManjianActivityUnieIs13(active) ? "元" : "件"
                    }减${item.value}元;`
                  }}
                </span>
                <span v-if="item.isGet">
                  {{
                    `已享${getManjianActivityFirstLineIs12(active) ? '满' : '每满' }${item.range}${
                        getManjianActivityUnieIs13(active) ? "元" : "件"
                    }减${item.value}元;`
                  }}
                </span>
                <span v-if="!item.isGet && !active.notShowAction">
                  {{
                    `再买${item.range}${
                      getManjianActivityUnieIs13(active) ? "元" : "件"
                    }可享受满${item.unRange}${
                      getManjianActivityUnieIs13(active) ? "元" : "件"
                    }减${item.unvalue}元`
                  }}
                </span>
              </span>
            </div>
          </div>
          <div class="store-collect" @click="collect(active)">
            <p>
              <span>去凑单 ></span>
            </p>
          </div>
        </div>
        <div class="store-total-activity" v-if="i.isGive">
          <div class="store-activity">赠品未满足赠送条件请重新选择</div>
        </div>
      </div>
      <!-- 购物车商品 -->
      <div class="marketGoods">
        <van-swipe-cell v-for="j in i.cartLineResDtoList " :key="j.id">
          <div class="goods-cart">
            <div class="selected">
              <van-checkbox
                v-model="j.isChoice"
                :disabled="!j.salePrice || j.saleStatus == 20"
                @click="itemIsChoice(j)"
              />
            </div>
            <div class="left">
              <van-image @click="commditDetai(j)" :src="findImg(j)" />
            </div>
            <div class="right">
              <p class="head">{{ findName(j) }}</p>
              <div class="middle">
                <van-tag
                  color="#ccc"
                  v-for="h in findTag(j).filter((k) => k.propertyType === 2)"
                  :key="h.value"
                  >{{ h.value }}</van-tag
                >
              </div>
              <div class="bottom">
                <div class="price">
                  <span> {{ findproPrice(j) }}</span>
                  <span :class="findproPrice(j) ? 'prime-price' : 'price'"
                    >¥{{ findPrice(j) }}</span
                  >
                </div>
                <div class="stepper">
                  <van-stepper
                    v-model="j.skuQuantity"
                    integer
                    @minus="(e) => itemCountChange(e, j, 'minus')"
                    @plus="(e) => itemCountChange(e, j, 'plus')"
                    @blur="(e) => itemCountChange(e, j, 'blur')"
                  />
                </div>
              </div>
            </div>
          </div>
          <template #right>
            <van-button
              square
              text="删除"
              @click="handleDelete(j)"
              type="danger"
              class="delete-button"
            />
          </template>
        </van-swipe-cell>
        <!-- 满赠商品 -->
        <div class="freeGoods">
          <van-swipe-cell v-for="j in i.exchangeCartLineList && i.exchangeCartLineList " :key="j.id">
            <div class="stroe-gift-goods">
              <div class="selected">
                <van-checkbox :value="activitygoodcodes.includes(j.skuCode)" disabled />
              </div>
              <van-card
                @click="commditDetai(j)"
                :num="j.skuQuantity"
                :title="findgift(j)"
                :thumb="findImage(j)"
                tag="赠品"
              >
                <template #tags>
                  <van-tag
                    color="#ccc"
                    v-for="h in findTag(j).filter((k) => k.propertyType === 2)"
                    :key="h.value"
                    >{{ h.value }}</van-tag
                  >
                </template>
              </van-card>
            </div>
            <template #right>
              <van-button
                square
                text="删除"
                @click="ZengpinDelete(j)"
                type="danger"
                class="delete-button"
              />
            </template>
          </van-swipe-cell>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="footerHeight"></div>

    <div class="market-submit">
      <div class="market-content">
        <!--全选-->
        <div class="all-select">
          <van-checkbox
            :disabled="!this.list.length"
            v-model="checked"
            @click="handleAllCheck"
            >全选</van-checkbox
          >
        </div>
        <!-- 价格,提交 -->
        <div v-show="!editFlag" class="price-submit">
          <div class="price">
            <p class="priceTextPrice">
              <span class="priceText">合计:</span>
              <span class="priceTextPriceP">{{
                `¥${(actualAmount.totalActualAmount || 0).toFixed(2)}`
              }}</span>
            </p>
            <p>
              <span>已选{{actualAmount.choiceCount || 0}}种{{(actualAmount.totalCount + ( findzeng(list) || 0)) || 0}}件,</span>
              <span>&nbsp;&nbsp;&nbsp;已优惠:</span>
              <span>{{
                `¥${(actualAmount.totalPromotionAmount || 0).toFixed(2)}`
              }}</span>
            </p>
          </div>
          <span class="van-btn-span">
            <van-button class="van-but" @click="toplace()">结算</van-button>
          </span>
        </div>
        <!-- 删除 -->
        <div v-show="editFlag" class="price-submit">
          <span class="van-btn-spans">
            <van-button
              type="danger"
              class="van-but-delete"
              @click="handleAllDelete()"
              >删除</van-button
            >
          </span>
        </div>
      </div>
    </div>
    <van-loading size="24px" v-show="market" vertical>加载中...</van-loading>
  </div>
</template>

<script>
import kong from "../../assets/icon/shoopbox_kong_img@2x.png";
import {
  queryMarketList,
  updateCount,
  isChoiceByIds,
  caculatePrice,
  deleteCommdit,
  deletezengpin
} from "./service";
import {addGoodsToMarket} from "../gift/service";
import { Toast } from "vant";
import _ from "loadsh";

export default {
  data() {
    return {
      defaultImg: "http://www.liby.com.cn/images/logo.png", // 默认图片
      checked: false, // 是否全选
      list: [], // 购物车列表
      market: false, // 加载显示
      activitylist: [], // 购物车列表返回的活动
      giftlist: [], // 计算价格返回的活动
      isifgift: "", // 某个活动参与的商品
      exchangeCartLineList: [], // 满赠活动列表
      PoolCode: "",
      giftskucode: "",
      giftid: "",
      activitygoodcodes: [],
      activityNo: "",
      range: "",
      count: "",
      listCount: 0, // 购物车商品数量
      actualAmount: {}, // 计算价格接口所有数据
      editFlag: false, // 编辑按钮
      activityrange: "",
      activitycount: "",
      unactivityrange: "",
      unactivitycount: "",
      zengpingshuliang:[],
      isGive:false,
      pricespread: "",
      isgive:true,
      kong,
    };
  },

  methods: {
    //赠品数量
        findzeng(item){
        let manzengpromition = _.flattenDeep(item?.map((r) => r?.exchangeCartLineList || []) || {})
        if(manzengpromition.length === 0){
          this.giftskuQuantity === 0
          return 
        }
        this.giftskuQuantity = manzengpromition?.map(item=>{
          return this.activitygoodcodes.includes(item.skuCode)?item.skuQuantity:0
        }).reduce((prev,curr)=>{
          return prev+curr
        }) 

        if(this.giftskuQuantity != undefined ) {
           return this.giftskuQuantity
        }else{
           return this.giftskuQuantity === 0
        }
    },    //店铺logo
    findlogo(i){
      return i?.ext ? JSON.parse(JSON.parse(i.ext).logo)[0]?.thumbUrl : 'http://www.liby.com.cn/images/logo.png'
    },
    // 是否显示活动卡片
    findMessagePromtion(active){
      return active?.messagePromtion ? active.messagePromtion.length : 0
    },
    //店铺跳转
    jumpstore(item){
      this.$router.push({path:'/storehome',query:{
        storeCode:item.storeCode
      }})
    },
     //加入购物车
    async addmarket(params) {
      const res = await addGoodsToMarket(params);
      if (res.status === "0") {
        Toast("加入成功");
        this.showMarket = false;
        this.getMarketList()
      } else {
        Toast(res.msg);
      }
    },
    // 获取满减活动12类型
    getManjianActivityFirstLineIs12(active) {
      return ((active.activityParameterResDto.PROMOTION_RULE.type == 1)
                  || (active.activityParameterResDto.PROMOTION_RULE.type == 2))
    },

    // 获取满减活动13类型
    getManjianActivityUnieIs13(active) {
      return ((active.activityParameterResDto.PROMOTION_RULE.type == 1)
                  || (active.activityParameterResDto.PROMOTION_RULE.type == 3))
    },

    //去凑单
    collect(item) {
      this.$router.push({
        path: "/collect-orders",
        query: {
          activityNo: item.activityNo,
        },
      });
    },

    //去选购
    collectgift(item) {
      // debugger;
      this.$router.push({
          path: "./gift",
          query: {
            activityNo: item.activityNo,
            PoolCode: item.itemPoolCode,
            range: `${this.getManjianActivityFirstLineIs12(item)? '满' : '每满'}${item.messagePromtion.filter(item => item.isGet)[0].range}${this.getManjianActivityUnieIs13(item)
                              ? "元"
                              : "件"}`,
            count: item.messagePromtion.filter(item => item.isGet)[0].number,
          },
        });
    },

    // 获取列表
    async getMarketList() {
      this.market = true;
      const res = await queryMarketList();
      if (res.status === "0") { 
        // 购物车列表
        this.list = res.data.items;
        this.market = false; 
        this.list.forEach((item)=>{
          item.exchangeCartLineList?.forEach((i)=>{
              item.exchangeQuantity = (item.exchangeQuantity?item.exchangeQuantity:0) +i.skuQuantity
          })
        })
        res.data?.items.forEach((item) => {
          // 满赠活动的skuCode
          this.giftskucode = item?.exchangeCartLineList?.map((r) => {
            return r.skuCode;
          });
          // 购物车返回的活动
          item?.activityList?.forEach((i) => {
            this.activitylist =
              i.activityParameterResDto.PROMOTION_RULE.values.slice(0, 1);
          });
        });

        this.calculatePrice1(res.data.items);
        this.listCount = _.flattenDeep(
          this.list.map((d) => d.cartLineResDtoList)
        ).length;

        // 全选
        if (this.list.length) {
          let allChecked = true;
          this.list.forEach((d) => {
            d?.cartLineResDtoList &&
              d.cartLineResDtoList
                .filter((item) => item.saleStatus == 10)
                .forEach((r) => {
                  if (!r.isChoice) {
                    allChecked = false;
                  }
                });
          });
          this.checked = allChecked;
        } else {
          this.checked = false;
        }
      } else {
        this.market = false;
        Toast.fail(res.msg);
      }
    },

    // 跳转商品详情
    commditDetai(item) {
      this.$router.push(
        `shopdetails?itemCode=${item.itemCode}&storeCode=${item.storeCode}`
      );
    },

    // 数量变化
    async itemCountChange(value, item, type) {
      const params = { "qp-id-eq": item.id };
      if (type === "plus") {
        params.skuQuantity = item.skuQuantity + 1;
      }
      if (type === "minus") {
        params.skuQuantity = item.skuQuantity - 1;
      }
      if (type === "blur") {
        params.skuQuantity = Number(value.target.value);
      }
      const res = await updateCount(params);
      if (res.status === "0") {
        this.getMarketList();
      } else {
        this.getMarketList();
        Toast(res.msg);
      }
    },

    // 选择
    async itemIsChoice(k) {
      if (k.saleStatus == 20) return;
      const arr = [];
      this.list.forEach((i) => {
        if (Array.isArray(i.cartLineResDtoList)) {
          i?.cartLineResDtoList.forEach((j) => {
            if (j.isChoice === 1) {
              arr.push(j.id);
            }
          });
        }
      });
      if (k.isChoice) {
        arr.push(k.id);
        const res = await isChoiceByIds({ ids: arr.toString() });
        if (res.status === "0") {
          this.getMarketList();
        }
      } else {
        const res = await isChoiceByIds({
          ids: arr.filter((h) => h !== k.id).toString(),
        });
        if (res.status === "0") {
          this.getMarketList();
        }
      }
    },

    // 赠品
    async Checkbox() {
      const arr = [];
      this.list.forEach((i) => {
        if (Array.isArray(i.cartLineResDtoList)) {
          i?.cartLineResDtoList.forEach((j) => {
            if (j.isChoice === 1) {
              arr.push(j.id);
            }
          });
        }
      });
      this.list.forEach((i) => {
        if (Array.isArray(i.exchangeCartLineList)) {
          i?.exchangeCartLineList.forEach((j) => {
            if (this.activitygoodcodes.includes(j.skuCode)) {
              j.isChoice = 1;
              arr.push(j.id);
            } else {
              j.isChoice = 0;
            }
          });
        }
      });
      const res = await isChoiceByIds({ ids: arr.toString() });
      if (res.status === "0") {
        this.getMarketList();
      } else {
        Toast(res.msg);
      }
    },

    // 计算价格
    async calculatePrice1(allGoods) {
      const obj = {};
      obj.calStep = 0;
      obj.orderLineList = [];

      allGoods.forEach((i) => {
        obj.storeCode = i.storeCode;
        if (Array.isArray(i.cartLineResDtoList)) {
          i?.cartLineResDtoList.forEach((j) => {
            if (j.isChoice === 1) {
              obj.orderLineList.push({
                id: j.id,
                storeCode: j.storeCode,
                skuCode: j.skuCode,
              });
            }
          });
        }
      });
      const res = await caculatePrice(obj);
      if (res.status === "0") {
        // 计算价格接口数据
        this.actualAmount = res.data;
        //判断赠品数量
        this.actualAmount.promotions.forEach((item)=>{
            item.exchangeRuleList.forEach((i)=>{
              i.exRuleDetailList.forEach((j)=>{
                item.ExchangeQuantity = (item.ExchangeQuantity?item.ExchangeQuantity:0)+j.number
              })
            })
        })
        this.actualAmount.promotions.forEach((item)=>{
          this.list.forEach((i)=>{
            i.isGive = false
            if(i.storeCode = item.storeCode){
              if(i.exchangeQuantity>item.ExchangeQuantity){
                    i.isGive = true
              }else{
                    i.isGive = false
              }
            }       
          })
        })
        // 活动
        this.giftlist = res.data.promotions;
        // 满赠
        let gitListMapping = [];
        this.activitygoodcodes = []  
        this.list.forEach((value, index, array) => {
          let currentPromotion = this.giftlist?.filter(item => item.order.storeCode === value.storeCode);
          if (!currentPromotion || !currentPromotion.length) return;
          currentPromotion = currentPromotion[0]
           
        
          // 获取满赠活动 & 满减活动的活动列表
          let huanggouActivity = value.activityList.filter(
            (item) => item.activityType === 8
          );
          let manjiangActivity = value.activityList.filter(
            (item) => item.activityType === 2
          );
          
          // 处理满赠活动
          huanggouActivity.forEach((huangouItem, index, array) => {
            let huangouPromotion = currentPromotion.exchangeRuleList.filter(
              (item) =>  item.activityNo === huangouItem.activityNo
            );
            
            currentPromotion.exchangeRuleList.forEach((value, index, array) => {
              value.exRuleDetailList.forEach((value1, index, array) => {
                gitListMapping = gitListMapping.concat(value1.skuCodeList)
              })
            })
            huangouItem.messagePromtion = []

            if (huangouPromotion?.length) {
              let length = huangouPromotion[0]?.exRuleDetailList.length;

              huangouItem.messagePromtion.push({
                isGet: true,
                range: huangouPromotion[0].exRuleDetailList[length - 1].range,
                number: huangouPromotion[0].exRuleDetailList[length - 1].number,
              })
              let itempoolcode = huangouPromotion[0].exRuleDetailList
              huangouItem.itemPoolCode = itempoolcode.pop().itemPoolCode
            }

            if ((huangouItem.messagePromtion.length == 1 && !huangouItem.messagePromtion[0].isGet) || !huangouItem.messagePromtion.length) {
              huangouItem.notShowAction = true

              huangouItem.messagePromtion = [{
                isGet: false,
                range: huangouItem.activityParameterResDto.PROMOTION_RULE.values[0].range,
                number: huangouItem.activityParameterResDto.PROMOTION_RULE.values[0].number,
              }]
            }
          });

          this.activitygoodcodes = gitListMapping;
          
          // 处理满减活动
          manjiangActivity.forEach((manjianItem, index, array) => {
            let manjianPromotion = currentPromotion.overlyingActivities.filter(
              (item) => item.activityNo === manjianItem.activityNo
            );
            let manjianNotPromotion = currentPromotion.tipActivities.filter(
              (item) => item.activityNo === manjianItem.activityNo
            );

            manjianItem.messagePromtion = []
            if (manjianPromotion?.length) {
              manjianItem.messagePromtion.push({
                isGet: true,
                range: manjianPromotion[0].promotionRule.range,
                value: manjianPromotion[0].promotionRule.value
              })
            }

            if (manjianNotPromotion.length && manjianNotPromotion[0].promotionRule.range) {

              manjianItem.messagePromtion.push({
                isGet: false,
                range: manjianNotPromotion[0].promotionRule.range,
                unRange: manjianNotPromotion[0].promotionRule.unRange,
                unvalue: manjianNotPromotion[0].promotionRule.unvalue,
              })
            } 

            if ((manjianItem.messagePromtion.length == 1 && !manjianItem.messagePromtion[0].isGet) || !manjianItem.messagePromtion.length) {
              manjianItem.notShowAction = true

              manjianItem.messagePromtion = [{
                isGet: false,
                range: manjianItem.activityParameterResDto.PROMOTION_RULE.values[0].range,
                value: manjianItem.activityParameterResDto.PROMOTION_RULE.values[0].value
              }]
            }
          });
        });
      } else {
        Toast(res.msg);
      }
    },

    findImage(gift) {
      return gift?.skuResDto?.mediaList
        ? gift.skuResDto.mediaList[0].url
        : "http://uat-oss-business-middle-platform.oss-cn-shenzhen.aliyuncs.com/202108202047596941428700340054843393.png?Expires=1944823679&OSSAccessKeyId=LTAI5tMBSdRFFsKwy54k3F3J&Signature=c%2B%2FQNCjX6kK7mi1wrIZlpdJjCMg%3D";
    },

    findgift(item) {
      return item?.skuResDto?.name ?? "暂无";
    },

    findImg(item) {
      return item?.skuResDto?.mediaList
        ? item.skuResDto.mediaList[0].url
        : "http://uat-oss-business-middle-platform.oss-cn-shenzhen.aliyuncs.com/202108202047596941428700340054843393.png?Expires=1944823679&OSSAccessKeyId=LTAI5tMBSdRFFsKwy54k3F3J&Signature=c%2B%2FQNCjX6kK7mi1wrIZlpdJjCMg%3D";
    },

    findName(item) {
      return item?.skuResDto?.name ?? "暂无";
    },

    findDesc(item) {
      return item?.skuResDto?.propertyValueList ?? [];
    },

    findTag(item) {
      return item?.skuResDto?.propertyValueList ?? [];
    },

    findPrice(item) {
      return item?.salePrice?.toFixed(2) ?? "0.00";
    },

    findproPrice(item) {
      return item?.proPrice?.toFixed(2);
    },

    // 跳转下单页
    toplace() {
      this.isgive = true
      this.list.forEach((item)=>{
        if(item.isGive === true){
          Toast("赠品条件不满足请重新选择");
          this.isgive =false
        }
      })
      if(this.isgive === true){
        let flag = false;
        this.list.forEach((d) => {
          d?.cartLineResDtoList?.forEach((r) => {
            if (r.isChoice) {
              flag = true;
            }
          });
        });
        this.Checkbox();
        if (flag) {
          this.$router.push("/placeorder");
        } else {
          Toast("请先勾选至少一个商品");
        }
      }
      
    },

    // 删除商品
    async handleDelete(item) {
      const res = await deleteCommdit(item.id);
      if (res.status === "0") {
        this.getMarketList();
        this.$parent.getMarketCount();
        Toast("删除成功");
      } else {
        Toast(res.msg);
      }
    },
    //单独删除赠品
    async ZengpinDelete(item) {
      const res = await deletezengpin(item.id);
      if (res.status === "0") {
        this.getMarketList();
        this.$parent.getMarketCount();
        Toast("删除成功");
      } else {
        Toast(res.msg);
      }
    },

    // 全选
    async handleAllCheck() {
      let allChecked = true;
      this.list.forEach((d) => {
        d?.cartLineResDtoList
          ?.filter((item) => item.saleStatus == 10)
          .forEach((r) => {
            if (!r.isChoice) {
              allChecked = false;
            }
          });
      });

      if (allChecked) {
        // 全部取消
        const res = await isChoiceByIds({ ids: "" });
        if (res.status === "0") {
          this.getMarketList();
        } else {
          Toast(res.msg);
        }
      } else {
        const arr = _.flattenDeep(
          this.list.map((d) => {
            return d?.cartLineResDtoList
              .filter((item) => item.saleStatus == 10)
              ?.map((r) => {
                return r.id;
              });
          })
        );
        // 全部选择
        const res = await isChoiceByIds({
          ids: arr.join(","),
        });
        if (res.status === "0") {
          this.getMarketList();
        }
      }
    },

    // 编辑
    handleClick() {
      this.editFlag = !this.editFlag;
    },

    // 批量删
    async handleAllDelete() {
      const arr = _.compact(
        _.flattenDeep(
          this.list.map((d) => {
            return d?.cartLineResDtoList?.map((r) => {
              if (r.isChoice) {
                return r.id;
              }
            });
          })
        )
      );
      if (arr.toString() == "") {
        Toast("请先选择商品");
      } else {
        const res = await deleteCommdit(arr.join(","));
        if (res.status === "0") {
          this.getMarketList();
          this.$parent.getMarketCount();
          Toast("删除成功");
        } else {
          Toast(res.msg);
        }
      }
    },
  },

  created() {
    this.getMarketList();
  },
  mounted() {
    if (this.$route.query.active) {
      this.active = this.$route.query.active;
    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
</style>
<style lang="less" scoped>
#market {
  width: 100%;
  height: 100%;
  padding-bottom: 45px;
  background: #f5f6f7;
  position: relative;

  .kongwarp {
    position: absolute;
    left: 38%;
    top: 25%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
    }
    p {
      color: #767676;
      font-size: 12px;
      margin-top: 8px;
    }
  }

  .market-header {
    border-top: 1px solid #f0f0f0;
    width: 100%;
    height: 44px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    p {
      margin: 0;
      font-size: 12px;
      color: #96979b;
    }
    a {
      padding: 4px 12px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      background-color: #0095da;
    }
  }

  .store-goods {
    width: 100%;
    margin: 8px 0 0 0;
    padding: 0 12px;
    border-radius: 12px;
    margin-bottom: 10px;
    .store-header {
      width: 100%;
      height: 36px;
      display: flex;
      align-items: center;
      background: #fff;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      .store-logo {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          display: inline-block;
          width: 15px;
          height: 15px;
        }
      }
      .store-title {
        flex: 1;
        text-align: left;
        font-size: 12px;
        color: #1c2529;
      }
    }
    .marketGoods {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      overflow: hidden;
    }
    /deep/ .freeGoods {
      background: #fff;
      .stroe-gift-goods {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px;
        .van-card {
          padding: 0;
          width: 100%;
          background-color: #fff;
          .van-card__thumb {
            width: 80px;
            height: 80px;
            .van-image {
              width: 80px;
              height: 80px;
            }
            .van-card__tag {
              right: 0;
              left: unset;
              .van-tag--danger {
                background-color: #ff912e;
              }
              .van-tag--mark {
                border-radius: 0 4px 0 4px;
              }
            }
          }
        }
        .van-card:not(:first-child) {
          margin: 0;
        }
        .selected {
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    /deep/ .store-gift {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: space-between;
      background-color: rgba(252, 155, 70, 0.6);
      font-size: 12px;
      line-height: 30px;
      padding: 0 8px;
      margin: 4px 0 0 0;
      p {
        width: auto;
        margin: 0;
        padding: 0;
        line-height: 30px;
      }
      &>p:first-child{
        color:#000;
        span{
          // color:#FF2731
        }
      }
      & > p:last-child {
        color: #0095da;
      }
    }
    .store-total-activity {
      width: 100%;
      height: 30px;
      margin-top: 4px;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      background-color: rgba(252, 155, 70, 0.6);
      .store-activity {
        flex: 1;
        height: 100%;
        font-size: 12px;
        line-height: 30px;
        p {
          width: auto;
          margin: 0;
          padding: 0;
          line-height: 30px;
          color: #1c2529;
        }
      }
      .store-collect {
        width: auto;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        color: rgb(255, 255, 255);
        p {
          width: auto;
          margin: 0;
          padding: 0;
          line-height: 30px;
          color: #0095da;
        }
      }
    }
    .goods-cart {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px;
      background: #fff;
      .selected {
        width: 30px;
        display: flex;
        justify-content: center;
        height: 30px;
      }
      .left {
        width: 85px;
        padding: 0;
        .van-image {
          width: 80px;
          height: 80px;
        }
      }
      .right {
        flex: 1;
        .head {
          margin: 0;
          width: 100%;
          height: 28px;
          line-height: 14px;
          font-size: 12px;
        }
        .middle {
          margin: 8px 0;
        }
        .bottom {
          display: flex;
          padding: 0 8px;
          justify-content: space-between;
          .price {
            color: red;
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 14px;
            .prime-price {
              text-decoration: line-through;
              font-size: 11px;
              color: #000;
            }
          }
        }
      }
    }
  }

  .market-submit {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 50px;
    .market-content {
      background: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 20px;
      .all-select {
        flex: 1;
        font-size: 12px;
      }
      .price-submit {
        flex: 3;
        display: flex;
        justify-content: flex-end;
        font-family: "Montserrat", sans-serif;
        height: 48px;

        p {
          margin: 0;
          padding: 0 6px;
        }
        p:first-child {
          color: red;
          text-align: right;
        }
        p:last-child {
          color: #ccc;
          font-size: 12px;
          text-align: right;
        }
        .van-but {
          background: #0095da;
          border-radius: 16px;
          color: #ffffff;
        }

        .van-but-delete {
          border-radius: 16px;
          color: #ffffff;
        }
        /deep/.van-button--info {
          background-color: #0095da;
        }
      }
    }
  }

  .footerHeight {
    height: 50px;
  }

  .delete-button {
    height: 100%;
  }

  .van-checkbox__icon--checked .van-icon {
    background: #0095da;
  }

  .priceText {
    font-size: 12px;
    margin-left: 3px;
    color: #000;
    padding-top: 2px;
    display: inline-block;
  }

  .van-btn-span {
    display: flex;
    align-items: center;
    .van-button {
      width: 80px;
      height: 40px;
      border: none;
      font-size: 14px;
      opacity: 1;
      background: #0095da;
      border-radius: 12px !important;
      margin: 4px 0;
    }
    margin-right: 6px;
  }
  .van-btn-spans {
    display: flex;
    align-items: center;
    .van-button {
      width: 70px;
      height: 40px;
      opacity: 1;
      border-radius: 12px !important;
      margin: 4px 0;
    }
    margin-right: 6px;
  }
  .priceTextPrice {
    padding-top: 5px !important;
  }
  .priceTextPriceP {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  }

  .van-stepper__input {
    font-family: "Montserrat", sans-serif;
  }

  .van-image {
    border-radius: 4px !important;
    img {
      border-radius: 4px !important;
    }
  }

  .van-swipe-cell__right {
    right: -2px;
  }
}
</style>
