/*
 * @Autor: syq
 * @Date: 2021-07-16 20:47:52
 */
import request from "../../request/request";
// import { stringify } from "qs";

const getExtItemList = async (activityNo, PoolCode) =>
  request({
    url: `/cartLine/getExtItemList?qp-activityNo-eq=${activityNo}&qp-itemPoolCode-in=${PoolCode}`,
    method: "get",
  });
const addGoodsToMarket = async (params) =>
  request({
    url: `/cartLine`,
    method: "post",
    data: params,
  });

export { getExtItemList, addGoodsToMarket };
